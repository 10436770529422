.shepherd-modal-overlay-container {
  opacity: 1 !important;
  fill: var(--body-opacity-55);
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  pointer-events: visible;
  z-index: 900;
}

div.shepherd-element,
.shepherd-element.shepherd-has-title header.shepherd-header {
  color: var(--base-text);
  background: var(--base-card);
  z-index: 900;

  .shepherd-arrow:before {
    background: var(--base-card) !important;
  }

  .shepherd-title {
    justify-content: center;
    color: var(--base-text);
    transform: translateY(40px);
  }
}

header.shepherd-header .shepherd-cancel-icon {
  color: var(--base-muted);
}

.shepherd-has-title div.shepherd-text {
  padding-top: 60px;
  font-size: 12px;
}

div.shepherd-text {
  padding: 10px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--base-text);
}

.shepherd-content ul {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .step-pointer {
    display: block;
    width: 5px;
    height: 5px;
    margin: 0 2px;
    background: var(--base-text);
    border-radius: 10px;
    opacity: .3;

    &.active {
      opacity: 1;
    }
  }
}

footer.shepherd-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button {
    min-width: 100px;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    color: var(--base-accent);
    background: transparent;

    &:not(:disabled):hover {
      color: var(--base-accent);
      background: var(--accent-opacity-1);
    }
  }
}
