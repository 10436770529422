@use "base/_mixins" as *;

textarea.mat-input-element {
  resize: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  margin-bottom: -5px;
  padding: 5px 5px 0;
  box-shadow: inset 0 0 0 50px var(--card-darken-1);
  box-sizing: border-box;
  -webkit-text-fill-color: var(--base-text);
}

.mat-form-field .mat-input-element {
  font-size: 14px;
  color: var(--base-text);
  caret-color: var(--base-text);
}

// Field with background
//

.tight-field {
  padding: 7px 16px;
  color: var(--base-text);
  background: var(--card-darken-6);
  border: 1px solid transparent;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-color .15s ease-in-out;

  &.disabled {
    background: var(--card-darken-3);
  }

  &.error {
    border-color: var(--base-danger);
  }

  input {
    width: 100%;
    color: var(--base-text);
    background: transparent;
    border: none;
    outline: none;
    caret-color: var(--base-text);

    &::placeholder {
      color: var(--base-muted);
    }
  }
}

// Input without underline
//

.transparent-field.mat-form-field-appearance-legacy {

  &.mat-form-field,
  .mat-form-field-wrapper,
  .mat-select-value {
    display: block;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-subscript-wrapper,
  .mat-form-field-underline,
  .mat-form-field-ripple {
    display: none;
  }
}

//===============================

// @section: Input with hint label
//

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper[ng-reflect-ng-switch="hint"] {
  margin-top: 8px;
}

.mat-form-field-appearance-legacy .mat-form-field-hint-wrapper .mat-hint {
  color: var(--base-muted);
}

//===============================

// @section: input with icon
//

.input-prefix {
  width: 25px;
  min-width: 25px;

  @include rtl-prop("margin-right", "margin-left", 15px, 0);

  span {
    display: inline-block;
    width: 100%;
    height: 25px;
  }

  app-icon {
    width: 22px;
    height: 22px;
    color: var(--base-muted);
  }
}

.form-field-icon {
  position: relative;
  display: flex;
  width: 100%;
  max-width: calc(100% - #{var(--depricated-form-field-icon-offset)});

  @include rtl-prop("margin-left", "margin-right", var(--depricated-form-field-icon-offset), 0);

  .input-prefix {
    position: absolute;
    top: 0;

    @include rtl-prop("right", "left", 100%, auto);
  }

  .mat-form-field {
    flex: 1 1 100%;
    min-width: 0;
  }
}

//.form-field-icon.small {
//  max-width: calc(100% - 22px);
//
//  @include rtl-prop("margin-left", "margin-right", 22px, 0);
//
//  .input-prefix {
//    top: 11px;
//    width: 15px;
//    min-width: 15px;
//
//    @include rtl-prop("margin-right", "margin-left", 7px, 0);
//
//    span {
//      height: 15px;
//    }
//
//    app-icon {
//      width: $form-field-icon-font-size-icon-small;
//      height: $form-field-icon-font-size-icon-small;
//    }
//  }
//}

.form-field-icon.big {
  max-width: calc(100% - #{var(--depricated-form-field-icon-offset-big)});

  @include rtl-prop("margin-left", "margin-right", var(--depricated-form-field-icon-offset-big), 0);

  .input-prefix {
    top: auto;
    bottom: -5px;
    width: 50px;
    min-width: 50px;

    span {
      height: 50px;
    }

    app-icon {
      @include square(35px);
    }
  }

  .mat-select .mat-select-value-text {
    font-size: 24px !important; //влияет на смещение
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-15px) scale(.9) perspective(100px) translateZ(.001px) !important;
  }
}

.lite-transfer {

  .mat-select .mat-select-value-text,
  .mat-input-element {
    font-size: 18px !important; //влияет на смещение
  }
}

//===============================

// @section: Custom input with prefix
//

.custom-input {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 3px;
  border-bottom: 1px solid var(--base-border);
  transition: border-bottom-color .3s ease-in-out;

  &.disabled {
    border-bottom-color: var(--disabled-opacity-6);
  }

  .label {
    width: 100%;
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: 400;
    color: var(--base-muted);
  }

  .input {
    display: flex;
    width: 100%;

    &[readonly] {
      cursor: default;
    }
  }

  .prefix {
    margin-right: 7px;
    font-size: 24px;
    line-height: 1.15;
    color: var(--base-text);

    [dir="rtl"] & {
      margin-left: 7px;
      margin-right: 0;
    }
  }

  input {
    flex-grow: 1;
    padding: 0;
    padding-bottom: 10px;
    font-size: 24px;
    line-height: 1.15;
    color: var(--base-text);
    background: transparent;
    border: none;
    outline: none;
  }
}

.mat-form-field.password-input {

  .mat-form-field-infix {
    @include rtl-prop("padding-right", "padding-left", 25px, 0);
  }
}
