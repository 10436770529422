@use "base/media" as *;
@use "base/_mixins" as *;

// @section: Card
//

mat-card.mat-card {
  background: var(--specific-card);
  border: 1px solid var(--base-card-border);
  border-radius: var(--depricated-card-border-radius);

  &:not([class*="mat-elevation-z"]) {
    box-shadow: var(--card-box-shadow);
  }
}

.mat-card-header .mat-card-header-text {
  margin: 0;
}

.card-top-panel {
  display: flex;
  flex-direction: column;
  align-items: center;

  .back-link {
    margin-top: -2px;

    @include rtl-prop("margin-right", "margin-left", 15px, 0);

    app-icon {
      width: 16px;
      height: 16px;
    }
  }
}

[dir="rtl"] .back-link {
  transform: rotate(180deg);
  transform-origin: center;
}

.card-title {
  font: 400 18px/1.3 var(--depricated-font-family-base);
  text-align: center;

  @include media("<xs") {
    font-size: 16px;
  }
}

.card-sm,
.mat-card.card-sm {
  max-width: 38%;
  margin: 0 auto;
  padding: 45px 30px;

  .card-top-panel {
    margin-bottom: 40px;

    h1 {
      margin-bottom: 0;
    }
  }

  @include media("<lg") {
    max-width: 54%;
  }

  @include media("<tablet") {
    max-width: 63%;
  }

  @include media(">=xs", "<sm") {
    max-width: 80%;
  }

  @include media("<xs") {
    max-width: 75%;
  }
}
