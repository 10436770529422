.custom-tooltip,
.mat-tooltip {
  margin: 10px !important;
  color: var(--base-text) !important;
  word-wrap: break-word;
  background: var(--base-card) !important;
  border: 1px solid var(--base-border) !important;
}

.pbsr-tooltip {
  margin: 10px !important;
  word-wrap: break-word;
  background: var(--base-card);
  border: 1px solid var(--base-menu);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .25);
  pointer-events: none;
}

.file-error-tooltip.mat-tooltip {
  font-size: 14px;
  color: var(--base-danger) !important;
  border: 1px solid var(--base-danger) !important;
}

.fee-tooltip.mat-tooltip {
  margin: 5px !important;
  font-size: 12px;
  background: var(--base-submenu) !important;
  border: 1px solid var(--base-card-border) !important;
  border-radius: 5px;
}
