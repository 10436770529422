@use "base/media" as *;

// @section: Accordion
//

.mat-accordion .mat-expansion-panel {
  background: transparent;
  border-bottom-color: var(--base-muted);

  &:not([class*="mat-elevation-z"]) {
    box-shadow: none;
  }

  &:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
    background: transparent;
  }

  .mat-expansion-panel-header {

    .mat-expansion-indicator {
      margin-bottom: 10px;

      &:after {
        color: var(--base-accent);
      }
    }
  }

  &.mat-expanded {

    .mat-expansion-indicator {

      &:after {
        margin-top: -10px;
        color: var(--base-accent);
      }
    }

    &.mat-expansion-panel-spacing {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}


.mat-accordion.steps {

  .mat-expansion-panel,
  .mat-expansion-panel:first-of-type,
  .mat-expansion-panel:last-of-type {
    border-radius: 0;
  }

  .mat-content {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 -24px;
    padding: 0 24px;
    background: var(--base-card);
  }

  .mat-expansion-panel-header-title {
    color: var(--base-text);

    @include media("<sm") {
      justify-content: center;

      span:last-child {
        display: none;
      }
    }
  }

  .mat-expansion-panel + .mat-expansion-panel {
    margin-top: 15px;
  }

  .mat-expansion-panel-body {
    padding-top: 35px;
    padding-bottom: 15px;

    @include media("<=md") {
      padding-left: 10px;
      padding-right: 10px;
    }

    @include media("<sm") {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
