//===============================

// @section: Advanced register wizard
//

.mat-select-panel.occupation-select-panel .mat-optgroup-label,
.mat-select-panel.occupation-select-panel .mat-option {
  height: auto;
  min-height: 3em;

  .mat-option-text {
    display: block;
    padding: 10px 0;
    overflow: visible;
    line-height: 1.8;
    white-space: normal;
  }
}

//===============================

// @section: Advanced: account select
//

.advanced-account-select-panel.mat-select-panel {
  position: absolute;
  top: 45px;
  max-width: 220px;
  max-height: 400px;
  border: 1px solid var(--theme-card-lighten-5-darken-5);
  border-radius: 8px;

  .mat-option {
    min-width: 80px;
    height: auto;
    padding: 16px;
    line-height: 1.3;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.mat-select.tight-field .mat-select-arrow {
  width: 5px;
  height: 5px;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: var(--base-muted);
  transform: rotate(45deg);
}
