@use "base/media" as *;

$mat-button-border-radius: 5px;

// @section: Buttons
//

a.mat-button,
button.mat-button,
a.mat-flat-button,
button.mat-flat-button {
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 500;
  line-height: var(--depricated-line-height-base);
  white-space: normal;
  border-radius: $mat-button-border-radius;

  &:disabled {
    color: var(--base-button-color) !important;
    cursor: not-allowed;
  }
}

a.mat-button,
button.mat-button {
  color: var(--base-muted);

  &:disabled {
    color: var(--base-disabled) !important;
  }

  .mat-button-focus-overlay {
    background-color: var(--base-muted);
  }
}

a.mat-flat-button,
button.mat-flat-button {

  &:not(:disabled) {
    color: var(--base-button-color);
  }

  &:disabled {
    background-color: var(--disabled-opacity-6) !important;
    background-image: none;
  }

  &[color="accent"] {
    background: var(--specific-accent);

    &:disabled {
      background-color: var(--theme-disabled-bg);
      background-image: none;
    }
  }

  &[color="warn"] {
    background: var(--warn-gradient);

    &:disabled {
      background-color: var(--base-muted);
      background-image: none;
    }
  }

  &.transparent {
    color: var(--base-accent) !important;
    background: transparent;
  }

  &.mat-live-color{
    background: var(--specific-live);

    &:disabled {
      background-color: var(--theme-disabled-bg);
      background-image: none;
    }
  }

  &.mat-demo-color {
    background: var(--specific-demo);

    &:disabled {
      background-color: var(--theme-disabled-bg);
      background-image: none;
    }
  }
}

a.mat-stroked-button,
button.mat-stroked-button {
  position: relative;
  padding: 0;
  border: 2px solid transparent;
  border-radius: $mat-button-border-radius;
  z-index: 4;

  &:after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: $mat-button-border-radius + 1;
    z-index: -1;
  }

  &[color="accent"] {
    color: var(--base-accent);

    &:after {
      background: var(--specific-accent);
    }
  }

  &[color="warn"] {
    color: var(--base-danger);

    &:after {
      background: var(--base-danger);
    }
  }

  &[color="accent"],
  &[color="warn"] {

    &:disabled,
    &[disabled],
    &.disabled {
      color: var(--theme-disabled-bg);
      cursor: not-allowed;

      &:after {
        background-color: var(--theme-disabled-bg);
        background-image: none;
      }
    }
  }

  .mat-button-wrapper {
    display: block;
    padding: 10px 25px;
    font-size: 15px;
    font-weight: 500;
    line-height: var(--depricated-line-height-base);
    white-space: normal;
    background: var(--base-content);
    border: 1px solid transparent;
    border-radius: $mat-button-border-radius;

    @include media ("<=xs") {
      padding: 10px 20px;
      font-size: 14px;
    }
  }

  &.narrow .mat-button-wrapper {
    padding: 4px 20px;
    font-size: 14px;
  }

  &.body-bg .mat-button-wrapper {
    background-color: var(--base-body);
  }

  &.base-menu-bg .mat-button-wrapper {
    background-color: var(--base-menu);
  }

  &.content-bg .mat-button-wrapper {
    background-color: var(--base-content);
  }

  &.add-button .mat-button-wrapper {
    display: flex;
    align-items: center;

    @include media ("<=tablet") {
      padding: 10px;
    }
  }

  &.card-bg .mat-button-wrapper {
    background-color: var(--base-card);
  }

  &.card-table-bg .mat-button-wrapper {
    background-color: var(--theme-card-lighten-3-darken-3);
  }

  &.mat-live-color {
    color: var(--base-live);

    &:after,
    .mat-button-focus-overlay {
      background: var(--specific-live);
    }
  }

  &.mat-demo-color {
    color: var(--optional-demo);

    &:after,
    .mat-button-focus-overlay {
      background: var(--specific-demo);
    }
  }

  &.mat-live-color,
  &.mat-demo-color {

    &:disabled {
      cursor: not-allowed;

      &:after {
        background-color: var(--theme-disabled-bg);
        background-image: none;
      }

      .mat-button-focus-overlay {
        background-color: transparent;
      }
    }
  }

  @include media ("<=xs") {
    width: 100%;
  }
}

a.mat-icon-button,
button.mat-icon-button {
  transition: background-color .15s ease-in-out;

  .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--base-accent);
  }
}

.action-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  color: var(--base-accent);
  background: var(--base-card);
  border: 2px solid var(--base-card);
  border-radius: 50%;
  box-shadow: var(--card-box-shadow);
  box-sizing: border-box;
  transition: background .2s ease-in, color .2s ease-in;


  &:not(:disabled):hover,
  &:not(:disabled).active {
    color: var(--base-card) !important;
    background: var(--base-accent);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: .6;
  }

  app-icon {
    width: 18px;
    height: 18px;
  }
}

//=======================

// @section: Public Advanced UI buttons
//

.primary-button,
a.primary-button {
  display: inline-block;
  min-width: 100px;
  padding: 10px 13px;
  font-weight: 400;
  text-align: center;
  background: var(--specific-accent);
  border: 1px solid transparent;
  border-radius: 6px;

  &,
  &:hover,
  &:visited,
  &:active {
    color: var(--base-button-color);
  }

  &.stroked-button {
    background: transparent;
    border-color: var(--base-accent);

    &,
    &:hover,
    &:active {
      color: var(--base-accent);
    }
  }
}

.mat-button-ripple .mat-ripple-element {
  background: var(--button-color-opacity-2) !important;
}

// mat-toggle-buttons

.mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay {
  opacity: 0 !important;
}

.mat-button-toggle.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  height: 40px;
  line-height: 40px;
}
