@use "base/_mixins" as *;

.alert {

  &-static {
    display: inline-table;
    max-width: 100%;
    border: 1px solid;
    border-radius: 5px;

    @include rtl("padding", 15px 30px 15px 15px, 15px 15px 15px 30px);

    app-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-top: 2px;

      @include rtl-prop("margin-right", "margin-left", 10px, 0);
    }
  }

  &-inner {
    display: flex;
    align-items: flex-start;
  }

  &-info {
    color: var(--base-accent);
    background: var(--accent-opacity-1);
    border-color: var(--base-accent);
  }

  &-error {
    color: #f0344a;
    background: rgba(#f0344a, .1);
    border-color: #f0344a;

    app-icon {
      margin-top: 0;
    }
  }

  &-success {
    color: var(--base-success);
    background: var(--success-opacity-1);
    border-color: var(--base-success);
  }
}

