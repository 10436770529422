@use "base/media" as *;

.title-link {
  font-size: 24px;
  line-height: 1.6;

  a {
    display: inline;
    margin-bottom: 10px;
    padding-bottom: 3px;
    border-bottom: 1px dashed;
    cursor: pointer;
    transition: border-bottom-color .15s ease-in-out, color .15s ease-in-out;

    &:hover {
      border-bottom-color: transparent;
    }
  }

  @include media("<lg") {
    font-size: 22px;
  }

  @include media("<sm") {
    font-size: 20px;
  }

  @include media("<xs") {
    font-size: 18px;
    text-align: center;
  }
}
