hr {
  height: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
  background: var(--base-border);
  border: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: color .15s ease-in-out;

  &:hover,
  &:active {
    color: var(--accent-lighten-5);
  }

  &,
  &:visited {
    color: var(--base-accent);
  }
}

b,
strong {
  font-weight: 500;
}

th {
  font-weight: 500;
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  appearance: none;

  &:focus {
    outline: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: normal;
  line-height: 1.3;
}

.text-danger {
  margin: 10px 0 20px;
  color: var(--base-danger);
}

.d-none {
  display: none !important;
}

.danger {
  color: var(--base-danger);
}

.success {
  color: var(--base-success);
}

.animated-appearance {
  animation: animated-appearance 1s;
}

@keyframes animated-appearance {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
