.mat-datepicker-toggle button.mat-icon-button {

  .mat-button-wrapper {
    position: relative;
    bottom: 1.5px;
    display: block;
  }

  .mat-datepicker-toggle-default-icon {
    transition: fill .3s ease-in-out;
    fill: var(--base-muted);

    &:hover,
    &:active {
      fill: var(--base-text);
    }
  }

  &[disabled] {

    .mat-datepicker-toggle-default-icon {

      &:hover,
      &:active {
        fill: var(--base-muted);
      }
    }
  }
}

.mat-datepicker-toggle .mat-icon-button.cdk-focused.cdk-keyboard-focused {

  .mat-button-focus-overlay {
    opacity: 0;
  }

  .mat-datepicker-toggle-default-icon {
    transition: fill .3s ease-in-out;
    fill: var(--base-text);
  }
}

.mat-datepicker-toggle.mat-datepicker-toggle-active {

  .mat-datepicker-toggle-default-icon {
    fill: var(--base-text);
  }
}

mat-datepicker-content.mat-datepicker-content {
  color: var(--base-text);
  background-color: transparent;

  .mat-calendar {
    color: var(--base-muted);
    background-color: var(--base-card);

    &-table-header {
      color: var(--base-muted);
    }

    &-body-label {
      color: var(--base-muted);
    }

    .mat-calendar-body-cell-content {
      color: var(--base-text);
    }

    .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
      color: var(--muted-opacity-7);
    }

    button.mat-calendar-period-button.mat-button {
      color: var(--base-text);

      &:hover {
        background: var(--muted-opacity-1);
      }
    }

    .mat-calendar-next-button,
    .mat-calendar-previous-button,
    .mat-calendar-arrow {
      color: var(--base-text);
      border-top-color: var(--base-text);
      fill: var(--base-text);
    }

    .mat-calendar-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
      background: var(--muted-opacity-7);
    }

    .mat-icon-button[disabled][disabled] {
      color: var(--muted-opacity-7);
    }

    .mat-calendar-body-selected {
      color: var(--base-accent);
      background: var(--muted-opacity-1);
    }

    .mat-calendar-body-today.mat-calendar-body-selected {
      box-shadow: inset 0 0 0 1px var(--base-accent);
    }

    .mat-calendar-today:not(.mat-calendar-body-selected) {
      border-color: var(--muted-opacity-5);
    }

    .mat-calendar-table-header-divider:after {
      background: var(--muted-opacity-1);
    }
  }
}
