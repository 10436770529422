@font-face {
  font-family: "Rubik";
  font-weight: 300;
  font-style: normal;
  src: url("/assets/fonts/Rubik/Rubik-Light.eot");
  src: url("/assets/fonts/Rubik/Rubik-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Rubik/Rubik-Light.woff") format("woff");
}

@font-face {
  font-family: "Rubik";
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/Rubik/Rubik-Regular.eot");
  src: url("/assets/fonts/Rubik/Rubik-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Rubik/Rubik-Regular.woff") format("woff");
}

@font-face {
  font-family: "Rubik";
  font-weight: 500;
  font-style: normal;
  src: url("/assets/fonts/Rubik/Rubik-Medium.eot");
  src: url("/assets/fonts/Rubik/Rubik-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Rubik/Rubik-Medium.woff") format("woff");
}

@font-face {
  font-family: "Rubik";
  font-weight: 600;
  font-style: normal;
  src: url("/assets/fonts/Rubik/Rubik-Bold.eot");
  src: url("/assets/fonts/Rubik/Rubik-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Rubik/Rubik-Bold.woff") format("woff");
}

@font-face {
  font-family: "Rubik";
  font-weight: 900;
  font-style: normal;
  src: url("/assets/fonts/Rubik/Rubik-Black.eot");
  src: url("/assets/fonts/Rubik/Rubik-Black.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Rubik/Rubik-Black.woff") format("woff");
}

@font-face {
  font-family: "B612Mono";
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/B612Mono/B612Mono-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/B612Mono/B612Mono-Regular.woff") format("woff");
}
