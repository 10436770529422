@use "base/_mixins" as *;

.mat-menu-panel {
  background: var(--base-card) !important;

  .mat-menu-item {
    color: var(--base-text);

    &:hover {
      color: var(--base-text);
      background: var(--theme-card-lighten-4-darken-4);
    }

    &[disabled],
    &:disabled {
      color: var(--text-opacity-5);
    }
  }

  .mat-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
  }
}


.mat-menu-panel.notification-menu {
  min-height: 0;
  border-radius: 8px;

  .mat-menu-item {
    @include rtl("text-align", right, left);
  }
}
