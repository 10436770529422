.mat-stepper-horizontal,
.mat-stepper-vertical {
  background: transparent !important;

  &.hide-header {

    .mat-horizontal-stepper-header-container {
      display: none !important;
    }
  }

  .mat-horizontal-content-container {
    padding: 0 !important;
  }
}
