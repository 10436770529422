@use "base/media" as *;
@use "base/_mixins" as *;

.page-title {
  display: flex;
  align-items: center;
  max-width: calc(100% - 200px);
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 400;

  .back-link {
    @include rtl-prop("margin-right", "margin-left", 15px, 0);

    app-icon {
      width: 16px;
      height: 16px;
    }
  }

  @include media("<sm") {
    max-width: 100%;
  }
}

.edit-link-icon {
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;

  app-icon {
    display: inline-block;
    width: 12px;
    height: 12px;

    @include rtl-prop("padding-right", "padding-left", 7px, 0);
  }
}

qrcode {
  display: block;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}

.copy-button {

  app-icon {
    width: 15px;
    height: 15px;
  }
}

.ngx-charts {
  float: none !important;
}

img[src*="rtmark.net"] {
  display: none;
}

.loyalty-banner {

  span {
    color: var(--base-accent);
  }
}

.tabs-buttons .mat-tab-body-content {
  height: auto;
  overflow: hidden;
}

.profile-settings-2fa-sms {

  app-input {
    display: block;
    max-width: 200px;
  }
}

.empty-message {
  margin: 30px 0;
  font-size: 22px;
  color: var(--base-muted);

  @include media("<sm") {
    font-size: 20px;
  }

  @include media("<xs") {
    margin: 20px 0;
    font-size: 18px;
  }
}

.table-filter {

  .mat-paginator-container {
    padding: 0;
  }

  .mat-paginator-navigation-next,
  .mat-paginator-navigation-previous {

    &.mat-icon-button {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }

  .mat-paginator-range-label {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mat-form-field.mat-form-field-appearance-legacy {

    .mat-form-field-infix {
      padding-bottom: 5px;
    }

    .mat-select,
    .mat-input-element,
    .mat-form-field-label {
      font-size: 14px !important;
    }
  }
}

.legal-doc a {

  &:hover {
    color: var(--specific-legal-doc-link-hover);
  }

  &,
  &:active,
  &:visited {
    line-height: 1.3;
    color: var(--specific-legal-doc-link);
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, .6);
}

.mat-autocomplete-panel {
  background: var(--base-content);

  .mat-option-text {
    font-size: 12px;
  }
}
