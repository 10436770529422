@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// mixin definition ; sets LTR and RTL within the same style call
// @see https://css-tricks.com/almanac/properties/d/direction/

@mixin rtl($prop, $ltr-value, $rtl-value) {
  #{$prop}: $ltr-value;

  [dir="rtl"] &,
  &[dir="rtl"] {
    #{$prop}: $rtl-value;
  }
}

@mixin rtl-prop($ltr-prop, $rtl-prop, $value, $reset-value) {
  #{$ltr-prop}: $value;

  [dir="rtl"] &,
  &[dir="rtl"] {
    #{$ltr-prop}: $reset-value;
    #{$rtl-prop}: $value;
  }
}

// To reverse padding (top left bottom right) -> (top right bottom left)

@function rtl-value($list) {
  @if length($list) == 4 {
    @return nth($list, 1) nth($list, 4) nth($list, 3) nth($list, 2);
  }
  @if length($list) == 5 {
    @return nth($list, 1) nth($list, 4) nth($list, 3) nth($list, 2) nth($list, 5);
  }

  @return $list;
}

// mixin for light and dark themes

@mixin theme($prop, $dark-theme-value, $light-theme-value) {
  #{$prop}: $dark-theme-value;

  .dark-theme &,
  &.dark-theme {
    #{$prop}: $dark-theme-value;
  }

  .light-theme &,
  &.light-theme {
    #{$prop}: $light-theme-value;
  }
}

// mixin for size

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin absolute($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

@mixin hidden() {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

@mixin visible() {
  position: static;
  visibility: visible;
  opacity: 1;
  z-index: 1;
}
