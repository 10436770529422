body * {
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    appearance: none;

    &-track {
      background-color: var(--card-darken-8);
    }

    &-thumb {
      background-color: var(--theme-card-lighten-7-darken-15);
      border-radius: 6px;
    }

    &-corner {
      background-color: var(--card-darken-8);
    }
  }
}
