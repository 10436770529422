@use "base/media" as *;
@use "base/_mixins" as *;

.mat-form-field {

  &.mat-focused .mat-select .mat-select-arrow {
    transform: rotate(45deg);
  }

  .mat-select {
    font-size: 16px;
  
    .mat-select-value {
      color: var(--base-text);
    }
  
    .mat-select-arrow {
      width: 5px;
      height: 5px;
      border-width: 0 1px 1px 0;
      border-style: solid;
      border-color: var(--base-border);
      transform: rotate(45deg);
    }
  
    &.color-accent {
  
      .mat-select-value {
        color: var(--base-accent);
      }
  
      .mat-select-arrow {
        margin-top: -3px;
        border-color: var(--base-accent);
      }
    }
  }
}

.mat-select-panel-wrap .mat-select-panel {
  background: var(--base-card);
}

.mat-select-panel-wrap .mat-select-panel.mat-select-custom-panel {
  max-height: 48vh;
  margin-top: 10px;
  border-radius: 0;

  @include rtl-prop("margin-left", "margin-right", 10px, 0);
}

.mat-select-panel-wrap .mat-select-panel.instrument-select-panel {
  min-width: calc(100% + 10px);
  max-height: 80vh;
  margin-top: 25px;
  margin-left: 10px;
  border-radius: 0;

  @media (max-height: 768px) {
    max-height: 48vh;
  }
}

.mat-select-panel-wrap .mat-select-panel .mat-option {
  color: var(--base-text);

  &:hover:not(.mat-option-disabled):not(.mat-active) {
    color: var(--base-accent);
    background-color: var(--theme-card-darken-1-darken-3);
  }

  &.mat-active,
  &.mat-active:hover {
    color: var(--base-accent);
    background: var(--theme-card-darken-3-darken-5);
  }

  &.mat-selected:not(.mat-option-disabled) {
    color: var(--base-accent);
    background: var(--theme-card-darken-3-darken-5);
  }

  &.mat-selected:not(.mat-option-disabled):hover,
  &.mat-selected:not(.mat-option-disabled).mat-active {
    color: var(--base-accent);
    background: var(--theme-card-darken-5-darken-6);
  }
}

.mat-select-panel-wrap app-balance {
  display: inline;
}

//===============================

// @section: Verification form
//

.verification-form {

  app-select {
    display: inline-block;
  }

  .mat-form-field {
    width: auto;
  }
}

//===============================

// @section: Currency select
//

.currency-select {

  .mat-form-field-infix {
    width: auto;
    min-width: 20px;
  }

  .mat-select-value {
    max-width: 200px;
    font-size: 24px;

    @include rtl-prop("margin-right", "margin-left", 5px, 0);

    @include media("<xs") {
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.pbs-select .mat-optgroup-label,
.pbs-select .mat-option-text {
  font-size: 14px;
}

.pbs-select .mat-optgroup-label {
  font-weight: 400;
  color: var(--base-disabled);
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: var(--theme-card-darken-5-darken-6);
}

.mat-option-text .highlight {
  color: var(--base-accent);
}
