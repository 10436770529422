.mat-tab-group.tabs-buttons,
.mat-tab-nav-bar.tabs-buttons {
  z-index: 1;

  &,
  .mat-tab-header {
    border-bottom: none;

    .mat-tab-link-container,
    .mat-tab-label-container {
      flex-grow: 0;
      background: var(--card-darken-5);
      border-radius: 5px;
    }
  }


  .mat-tab-link,
  .mat-tab-label {
    position: relative;
    min-width: 120px;
    height: 34px;
    padding: 8px 15px;
    font-weight: 400;
    color: var(--base-accent);
    box-sizing: border-box;
    opacity: 1;
    transition: color .4s ease-in-out;

    &:hover {
      color: var(--accent-lighten-10);
    }

    &-active {
      color: var(--base-button-color);
      border-radius: 5px;
      cursor: default;

      &:before {
        background: var(--base-accent);
      }

      &:hover {
        color: var(--base-button-color);
      }
    }

    &-active.mat-focus-indicator.cdk-keyboard-focused:not(.mat-tab-disabled) {
      color: var(--base-button-color);
    }

    &.mat-focus-indicator.cdk-keyboard-focused:not(.mat-tab-disabled) {
      color: var(--accent-lighten-10);
      background: transparent;
    }
  }

  .mat-ink-bar {
    height: 100%;
    background: var(--base-accent) !important;
    border: 1px solid transparent;
    border-radius: 5px;
    box-sizing: border-box;
    visibility: visible !important;
    z-index: -1;
  }
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled) {
  background: transparent;
}

