.mat-form-field {

  .mat-form-field-label-wrapper {
    top: -.8em;
    padding-top: .8em;
    overflow: visible;
  }

  .mat-form-field-underline {
    position: static;
  }

  .mat-placeholder-required.mat-form-field-required-marker {
    color: var(--base-accent);
  }

  .mat-form-field-infix {
    border-top: none;
  }
}

.mat-form-field .mat-form-field-label {
  font-size: 16px;
}

.mat-form-field.mat-form-field-disabled .mat-form-field-label {
  color: var(--disabled-opacity-6);
}

.mat-form-field.mat-form-field-appearance-legacy .mat-form-field-label {
  color: var(--base-muted) !important;
}

.mat-form-field.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-label {
  color: var(--disabled-opacity-6) !important;
}

.mat-form-field.mat-focused.mat-primary .mat-form-field-label {
  color: var(--base-accent);
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  width: 100% !important;
  font-size: 12px;
  transform: translateY(-15px) scale(.9) perspective(100px) translateZ(.001px) !important;
}

.mat-form-field.mat-form-field-disabled {

  &.mat-form-field-appearance-legacy {

    .mat-form-field-underline {
      background-color: var(--disabled-opacity-6);
      background-image: none;
    }

    textarea {
      cursor: not-allowed;
    }
  }

  .mat-form-field-infix {

    &,
    input,
    mat-select,
    .mat-select-placeholder {
      cursor: not-allowed;
    }
  }

  .mat-select .mat-select-value {
    color: var(--disabled-opacity-6);
  }

  .mat-select-arrow-wrapper .mat-select-arrow {
    border-color: var(--disabled-opacity-6);
  }
}

.mat-form-field.mat-form-field-appearance-legacy {

  .mat-form-field-underline {
    background-color: var(--base-border);
  }

  .mat-form-field-infix {
    padding: 9px 0;
  }
}

.mat-form-field.mat-focused.mat-primary .mat-form-field-ripple {
  height: 1px;
  background-color: var(--base-border-active);
}

.mat-form-field.mat-form-field-appearance-outline {
  border-radius: 5px;

  &.mat-focused .mat-form-field-outline-thick,
  &.mat-form-field-disabled .mat-form-field-outline,
  .mat-form-field-outline-thick,
  .mat-form-field-outline {
    color: transparent;
  }
}

//===============================

// @section: Form field with custom label
//

.form-field-custom-label {
  font-family: var(--depricated-font-family-base);
  font-size: 16px;
  font-weight: 400;
  color: var(--base-muted);

  .required-marker {
    color: var(--base-accent);
  }
}

.mat-form-field.with-custom-label {

  .mat-form-field-required-marker {
    display: none;
  }

  &.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
  padding: 0;
}

.mat-form-field-appearance-outline .mat-form-field-infix,
.lots-input.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 10px 0;
}

.account-list {

  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(-5%);
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    background: var(--theme-card-darken-2-darken-4);
    border: none;
    border-radius: 8px;
  }

  .mat-focused .mat-form-field-outline {
    background: var(--card-darken-5);
    border: 1px solid var(--theme-card-lighten-5-darken-5);
  }

  .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
    background-color: var(--card-darken-6) !important;
    opacity: 1;
    transition: opacity .6s cubic-bezier(.25, .8, .25, 1);
  }

  .mat-form-field-flex > .mat-form-field-infix .mat-select {
    margin-top: 5px;
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-end {
    border: none !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 16px;
  }
}
