@use "base/_mixins" as *;

.mat-slide-toggle-label {

  .mat-slide-toggle {

    &-bar {
      height: 24px;
      background: var(--base-muted);
      border-radius: 12px;
    }

    &-thumb-container {
      top: 2px;

      @include rtl-prop("left", "right", 2px, auto);
    }

    &-thumb {
      background-color: var(--base-button-color);
    }
  }
}

.mat-slide-toggle.mat-checked {

  .mat-slide-toggle-label {

    .mat-slide-toggle-bar {
      background: var(--base-accent);
    }

    .mat-slide-toggle-thumb {
      background-color: var(--base-button-color);
    }

    .mat-slide-toggle-thumb-container {
      @include rtl("transform", translate3d(12px, 0, 0), translate3d(-12px, 0, 0));
    }
  }
}
