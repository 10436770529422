//===============================

// @section: checkbox, radio
//

.mat-checkbox {
  width: 100%;

  .mat-checkbox-layout {
    align-items: flex-start;
    width: 100%;
    white-space: normal;

    .mat-checkbox-label {
      flex-grow: 1;
      line-height: 20px;
    }
  }

  .mat-checkbox-label {
    color: var(--base-text);
  }

  .mat-checkbox-inner-container {
    width: 15px;
    height: 15px;
    margin: 2px 10px 0 0;
  }

  .mat-checkbox-frame {
    border-width: 1px;
    border-color: var(--base-border);
  }
}

.mat-checkbox.mat-checkbox-disabled {
  cursor: not-allowed;

  &:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border-color: var(--disabled-opacity-6);
  }

  .mat-checkbox-label {
    color: var(--disabled-opacity-6);
  }
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--base-accent) !important;
}

.mat-radio-container .mat-radio-outer-circle {
  border-color: var(--base-accent);
}

.mat-radio-label-content {
  color: var(--base-text);
}

.type-live {

  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: var(--base-live);
  }
}

.type-demo {

  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: var(--optional-demo);
  }
}

.type-master {

  &.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: var(--master-account);
  }
}

.type-investment {

  &.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: var(--follower-account);
  }
}
