@use "base/media" as *;
@use "base/_mixins" as *;

//=======================

// @section: Base
//

html[dir="rtl"] {
  direction: rtl;
}

html.cdk-global-scrollblock {
  position: static;
  overflow-y: hidden;
}

html,
body {
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo-loader {
  @include absolute();
  background-image: var(--logo-loader);
  background-repeat: no-repeat;
  background-position: center;
}

html.no-scroll {
  overflow-y: hidden;

  body {
    overflow-y: auto;
  }
}

html.html-external {
  overflow-x: hidden;
}

.mat-sidenav-container,
.mat-sidenav-content,
.page {
  min-height: 100vh;
}

.page {
  width: 100%;
  box-sizing: border-box;
}

body,
.mat-drawer-container {
  font-family: var(--depricated-font-family-base);
  font-size: 14px;
  line-height: var(--depricated-line-height-base);
  color: var(--base-text);
  letter-spacing: .2px;
  background-color: var(--base-body);
  background-image: var(--specific-body-img);
  background-repeat: var(--specific-body-img-repeat);
  background-position: var(--specific-body-img-position);
  background-size: var(--specific-body-img-size);
  background-attachment: var(--specific-body-img-attachment);
}

.container {
  width: 100%;
  max-width: var(--depricated-container-lg-max-width);
  margin: 0 auto;
  box-sizing: border-box;

  @include media("<lg") {
    max-width: var(--depricated-container-md-max-width);
  }

  @include media("<=md") {
    max-width: 720px;
  }

  @include media("<sm") {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.content {
  flex-grow: 1;
  max-height: none;
  padding: 45px 30px 30px;
  box-sizing: border-box;

  @include media("<=md") {
    padding: 30px 15px;
  }
}

app-common-banners-container.not-empty + .content {
  padding: 15px 30px 30px;
}

app-common-banners-container.not-empty + .content app-helpdesk {
  padding-top: 48px;
}

//=======================

// @section: Full layout
//

.full-layout.mat-drawer-container {
  width: 100%;
  max-width: var(--depricated-container-lg-max-width);
  margin: 0 auto;
  box-sizing: border-box;

  .mat-drawer-inner-container {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: visible !important;
  }

  @include media("<lg") {
    max-width: var(--depricated-container-md-max-width);
  }

  @include media("<=md") {
    max-width: 100%;
  }
}

.mat-drawer-backdrop {

  &.mat-drawer-shown {
    background: var(--body-opacity-55) !important;
  }

  @include media("<=md") {
    top: var(--depricated-header-min-height) !important;
  }
}

//=======================

// @section: External layout
//

body.external {
  overflow-x: hidden;
  background-color: var(--base-external);
  background-image: var(--specific-external-body-img);
  background-repeat: var(--specific-external-body-img-repeat);
  background-position: var(--specific-external-body-img-position);
  background-size: var(--specific-external-body-img-size);

  .content {
    padding-top: 0;
    padding-bottom: 0;
  }

  app-logo:before {
    background: transparent;
  }

  .footer a {
    color: var(--specific-external-footer-link);

    &:hover {
      color: var(--specific-external-footer-link-hover);
    }
  }
}

//=======================

// @section: CDK-overlay
//

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: .1;
}
