@font-face {
  font-family: "Rubik";
  font-weight: 300;
  font-style: normal;
  src: url("/assets/fonts/Rubik/Rubik-Light.eot");
  src: url("/assets/fonts/Rubik/Rubik-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Rubik/Rubik-Light.woff") format("woff");
}
@font-face {
  font-family: "Rubik";
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/Rubik/Rubik-Regular.eot");
  src: url("/assets/fonts/Rubik/Rubik-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Rubik/Rubik-Regular.woff") format("woff");
}
@font-face {
  font-family: "Rubik";
  font-weight: 500;
  font-style: normal;
  src: url("/assets/fonts/Rubik/Rubik-Medium.eot");
  src: url("/assets/fonts/Rubik/Rubik-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Rubik/Rubik-Medium.woff") format("woff");
}
@font-face {
  font-family: "Rubik";
  font-weight: 600;
  font-style: normal;
  src: url("/assets/fonts/Rubik/Rubik-Bold.eot");
  src: url("/assets/fonts/Rubik/Rubik-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Rubik/Rubik-Bold.woff") format("woff");
}
@font-face {
  font-family: "Rubik";
  font-weight: 900;
  font-style: normal;
  src: url("/assets/fonts/Rubik/Rubik-Black.eot");
  src: url("/assets/fonts/Rubik/Rubik-Black.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Rubik/Rubik-Black.woff") format("woff");
}
@font-face {
  font-family: "B612Mono";
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/B612Mono/B612Mono-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/B612Mono/B612Mono-Regular.woff") format("woff");
}
.page-title {
  display: flex;
  align-items: center;
  max-width: calc(100% - 200px);
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 400;
}
.page-title .back-link {
  margin-right: 15px;
}
[dir=rtl] .page-title .back-link, .page-title .back-link[dir=rtl] {
  margin-right: 0;
  margin-left: 15px;
}
.page-title .back-link app-icon {
  width: 16px;
  height: 16px;
}
@media (max-width: 599px) {
  .page-title {
    max-width: 100%;
  }
}

.edit-link-icon {
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
}
.edit-link-icon app-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  padding-right: 7px;
}
[dir=rtl] .edit-link-icon app-icon, .edit-link-icon app-icon[dir=rtl] {
  padding-right: 0;
  padding-left: 7px;
}

qrcode {
  display: block;
}
qrcode img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.copy-button app-icon {
  width: 15px;
  height: 15px;
}

.ngx-charts {
  float: none !important;
}

img[src*="rtmark.net"] {
  display: none;
}

.loyalty-banner span {
  color: var(--base-accent);
}

.tabs-buttons .mat-tab-body-content {
  height: auto;
  overflow: hidden;
}

.profile-settings-2fa-sms app-input {
  display: block;
  max-width: 200px;
}

.empty-message {
  margin: 30px 0;
  font-size: 22px;
  color: var(--base-muted);
}
@media (max-width: 599px) {
  .empty-message {
    font-size: 20px;
  }
}
@media (max-width: 479px) {
  .empty-message {
    margin: 20px 0;
    font-size: 18px;
  }
}

.table-filter .mat-paginator-container {
  padding: 0;
}
.table-filter .mat-paginator-navigation-next.mat-icon-button,
.table-filter .mat-paginator-navigation-previous.mat-icon-button {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.table-filter .mat-paginator-range-label {
  margin-left: 15px;
  margin-right: 15px;
}
.table-filter .mat-form-field.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding-bottom: 5px;
}
.table-filter .mat-form-field.mat-form-field-appearance-legacy .mat-select,
.table-filter .mat-form-field.mat-form-field-appearance-legacy .mat-input-element,
.table-filter .mat-form-field.mat-form-field-appearance-legacy .mat-form-field-label {
  font-size: 14px !important;
}

.legal-doc a:hover {
  color: var(--specific-legal-doc-link-hover);
}
.legal-doc a, .legal-doc a:active, .legal-doc a:visited {
  line-height: 1.3;
  color: var(--specific-legal-doc-link);
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.6);
}

.mat-autocomplete-panel {
  background: var(--base-content);
}
.mat-autocomplete-panel .mat-option-text {
  font-size: 12px;
}

html[dir=rtl] {
  direction: rtl;
}

html.cdk-global-scrollblock {
  position: static;
  overflow-y: hidden;
}

html,
body {
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: var(--logo-loader);
  background-repeat: no-repeat;
  background-position: center;
}

html.no-scroll {
  overflow-y: hidden;
}
html.no-scroll body {
  overflow-y: auto;
}

html.html-external {
  overflow-x: hidden;
}

.mat-sidenav-container,
.mat-sidenav-content,
.page {
  min-height: 100vh;
}

.page {
  width: 100%;
  box-sizing: border-box;
}

body,
.mat-drawer-container {
  font-family: var(--depricated-font-family-base);
  font-size: 14px;
  line-height: var(--depricated-line-height-base);
  color: var(--base-text);
  letter-spacing: 0.2px;
  background-color: var(--base-body);
  background-image: var(--specific-body-img);
  background-repeat: var(--specific-body-img-repeat);
  background-position: var(--specific-body-img-position);
  background-size: var(--specific-body-img-size);
  background-attachment: var(--specific-body-img-attachment);
}

.container {
  width: 100%;
  max-width: var(--depricated-container-lg-max-width);
  margin: 0 auto;
  box-sizing: border-box;
}
@media (max-width: 1279px) {
  .container {
    max-width: var(--depricated-container-md-max-width);
  }
}
@media (max-width: 960px) {
  .container {
    max-width: 720px;
  }
}
@media (max-width: 599px) {
  .container {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.content {
  flex-grow: 1;
  max-height: none;
  padding: 45px 30px 30px;
  box-sizing: border-box;
}
@media (max-width: 960px) {
  .content {
    padding: 30px 15px;
  }
}

app-common-banners-container.not-empty + .content {
  padding: 15px 30px 30px;
}

app-common-banners-container.not-empty + .content app-helpdesk {
  padding-top: 48px;
}

.full-layout.mat-drawer-container {
  width: 100%;
  max-width: var(--depricated-container-lg-max-width);
  margin: 0 auto;
  box-sizing: border-box;
}
.full-layout.mat-drawer-container .mat-drawer-inner-container {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: visible !important;
}
@media (max-width: 1279px) {
  .full-layout.mat-drawer-container {
    max-width: var(--depricated-container-md-max-width);
  }
}
@media (max-width: 960px) {
  .full-layout.mat-drawer-container {
    max-width: 100%;
  }
}

.mat-drawer-backdrop.mat-drawer-shown {
  background: var(--body-opacity-55) !important;
}
@media (max-width: 960px) {
  .mat-drawer-backdrop {
    top: var(--depricated-header-min-height) !important;
  }
}

body.external {
  overflow-x: hidden;
  background-color: var(--base-external);
  background-image: var(--specific-external-body-img);
  background-repeat: var(--specific-external-body-img-repeat);
  background-position: var(--specific-external-body-img-position);
  background-size: var(--specific-external-body-img-size);
}
body.external .content {
  padding-top: 0;
  padding-bottom: 0;
}
body.external app-logo:before {
  background: transparent;
}
body.external .footer a {
  color: var(--specific-external-footer-link);
}
body.external .footer a:hover {
  color: var(--specific-external-footer-link-hover);
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.1;
}

hr {
  height: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
  background: var(--base-border);
  border: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: color 0.15s ease-in-out;
}
a:hover, a:active {
  color: var(--accent-lighten-5);
}
a, a:visited {
  color: var(--base-accent);
}

b,
strong {
  font-weight: 500;
}

th {
  font-weight: 500;
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  appearance: none;
}
button:focus {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: normal;
  line-height: 1.3;
}

.text-danger {
  margin: 10px 0 20px;
  color: var(--base-danger);
}

.d-none {
  display: none !important;
}

.danger {
  color: var(--base-danger);
}

.success {
  color: var(--base-success);
}

.animated-appearance {
  animation: animated-appearance 1s;
}

@keyframes animated-appearance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.mat-accordion .mat-expansion-panel {
  background: transparent;
  border-bottom-color: var(--base-muted);
}
.mat-accordion .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.mat-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: transparent;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  margin-bottom: 10px;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator:after {
  color: var(--base-accent);
}
.mat-accordion .mat-expansion-panel.mat-expanded .mat-expansion-indicator:after {
  margin-top: -10px;
  color: var(--base-accent);
}
.mat-accordion .mat-expansion-panel.mat-expanded.mat-expansion-panel-spacing {
  margin-top: 0;
  margin-bottom: 0;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-body {
  padding: 0;
}

.mat-accordion.steps .mat-expansion-panel,
.mat-accordion.steps .mat-expansion-panel:first-of-type,
.mat-accordion.steps .mat-expansion-panel:last-of-type {
  border-radius: 0;
}
.mat-accordion.steps .mat-content {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 -24px;
  padding: 0 24px;
  background: var(--base-card);
}
.mat-accordion.steps .mat-expansion-panel-header-title {
  color: var(--base-text);
}
@media (max-width: 599px) {
  .mat-accordion.steps .mat-expansion-panel-header-title {
    justify-content: center;
  }
  .mat-accordion.steps .mat-expansion-panel-header-title span:last-child {
    display: none;
  }
}
.mat-accordion.steps .mat-expansion-panel + .mat-expansion-panel {
  margin-top: 15px;
}
.mat-accordion.steps .mat-expansion-panel-body {
  padding-top: 35px;
  padding-bottom: 15px;
}
@media (max-width: 960px) {
  .mat-accordion.steps .mat-expansion-panel-body {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 599px) {
  .mat-accordion.steps .mat-expansion-panel-body {
    padding-left: 0;
    padding-right: 0;
  }
}

.alert-static {
  display: inline-table;
  max-width: 100%;
  border: 1px solid;
  border-radius: 5px;
  padding: 15px 30px 15px 15px;
}
[dir=rtl] .alert-static, .alert-static[dir=rtl] {
  padding: 15px 15px 15px 30px;
}
.alert-static app-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-right: 10px;
}
[dir=rtl] .alert-static app-icon, .alert-static app-icon[dir=rtl] {
  margin-right: 0;
  margin-left: 10px;
}
.alert-inner {
  display: flex;
  align-items: flex-start;
}
.alert-info {
  color: var(--base-accent);
  background: var(--accent-opacity-1);
  border-color: var(--base-accent);
}
.alert-error {
  color: #f0344a;
  background: rgba(240, 52, 74, 0.1);
  border-color: #f0344a;
}
.alert-error app-icon {
  margin-top: 0;
}
.alert-success {
  color: var(--base-success);
  background: var(--success-opacity-1);
  border-color: var(--base-success);
}

a.mat-button,
button.mat-button,
a.mat-flat-button,
button.mat-flat-button {
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 500;
  line-height: var(--depricated-line-height-base);
  white-space: normal;
  border-radius: 5px;
}
a.mat-button:disabled,
button.mat-button:disabled,
a.mat-flat-button:disabled,
button.mat-flat-button:disabled {
  color: var(--base-button-color) !important;
  cursor: not-allowed;
}

a.mat-button,
button.mat-button {
  color: var(--base-muted);
}
a.mat-button:disabled,
button.mat-button:disabled {
  color: var(--base-disabled) !important;
}
a.mat-button .mat-button-focus-overlay,
button.mat-button .mat-button-focus-overlay {
  background-color: var(--base-muted);
}

a.mat-flat-button:not(:disabled),
button.mat-flat-button:not(:disabled) {
  color: var(--base-button-color);
}
a.mat-flat-button:disabled,
button.mat-flat-button:disabled {
  background-color: var(--disabled-opacity-6) !important;
  background-image: none;
}
a.mat-flat-button[color=accent],
button.mat-flat-button[color=accent] {
  background: var(--specific-accent);
}
a.mat-flat-button[color=accent]:disabled,
button.mat-flat-button[color=accent]:disabled {
  background-color: var(--theme-disabled-bg);
  background-image: none;
}
a.mat-flat-button[color=warn],
button.mat-flat-button[color=warn] {
  background: var(--warn-gradient);
}
a.mat-flat-button[color=warn]:disabled,
button.mat-flat-button[color=warn]:disabled {
  background-color: var(--base-muted);
  background-image: none;
}
a.mat-flat-button.transparent,
button.mat-flat-button.transparent {
  color: var(--base-accent) !important;
  background: transparent;
}
a.mat-flat-button.mat-live-color,
button.mat-flat-button.mat-live-color {
  background: var(--specific-live);
}
a.mat-flat-button.mat-live-color:disabled,
button.mat-flat-button.mat-live-color:disabled {
  background-color: var(--theme-disabled-bg);
  background-image: none;
}
a.mat-flat-button.mat-demo-color,
button.mat-flat-button.mat-demo-color {
  background: var(--specific-demo);
}
a.mat-flat-button.mat-demo-color:disabled,
button.mat-flat-button.mat-demo-color:disabled {
  background-color: var(--theme-disabled-bg);
  background-image: none;
}

a.mat-stroked-button,
button.mat-stroked-button {
  position: relative;
  padding: 0;
  border: 2px solid transparent;
  border-radius: 5px;
  z-index: 4;
}
a.mat-stroked-button:after,
button.mat-stroked-button:after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 6px;
  z-index: -1;
}
a.mat-stroked-button[color=accent],
button.mat-stroked-button[color=accent] {
  color: var(--base-accent);
}
a.mat-stroked-button[color=accent]:after,
button.mat-stroked-button[color=accent]:after {
  background: var(--specific-accent);
}
a.mat-stroked-button[color=warn],
button.mat-stroked-button[color=warn] {
  color: var(--base-danger);
}
a.mat-stroked-button[color=warn]:after,
button.mat-stroked-button[color=warn]:after {
  background: var(--base-danger);
}
a.mat-stroked-button[color=accent]:disabled, a.mat-stroked-button[color=accent][disabled], a.mat-stroked-button[color=accent].disabled, a.mat-stroked-button[color=warn]:disabled, a.mat-stroked-button[color=warn][disabled], a.mat-stroked-button[color=warn].disabled,
button.mat-stroked-button[color=accent]:disabled,
button.mat-stroked-button[color=accent][disabled],
button.mat-stroked-button[color=accent].disabled,
button.mat-stroked-button[color=warn]:disabled,
button.mat-stroked-button[color=warn][disabled],
button.mat-stroked-button[color=warn].disabled {
  color: var(--theme-disabled-bg);
  cursor: not-allowed;
}
a.mat-stroked-button[color=accent]:disabled:after, a.mat-stroked-button[color=accent][disabled]:after, a.mat-stroked-button[color=accent].disabled:after, a.mat-stroked-button[color=warn]:disabled:after, a.mat-stroked-button[color=warn][disabled]:after, a.mat-stroked-button[color=warn].disabled:after,
button.mat-stroked-button[color=accent]:disabled:after,
button.mat-stroked-button[color=accent][disabled]:after,
button.mat-stroked-button[color=accent].disabled:after,
button.mat-stroked-button[color=warn]:disabled:after,
button.mat-stroked-button[color=warn][disabled]:after,
button.mat-stroked-button[color=warn].disabled:after {
  background-color: var(--theme-disabled-bg);
  background-image: none;
}
a.mat-stroked-button .mat-button-wrapper,
button.mat-stroked-button .mat-button-wrapper {
  display: block;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 500;
  line-height: var(--depricated-line-height-base);
  white-space: normal;
  background: var(--base-content);
  border: 1px solid transparent;
  border-radius: 5px;
}
@media (max-width: 480px) {
  a.mat-stroked-button .mat-button-wrapper,
button.mat-stroked-button .mat-button-wrapper {
    padding: 10px 20px;
    font-size: 14px;
  }
}
a.mat-stroked-button.narrow .mat-button-wrapper,
button.mat-stroked-button.narrow .mat-button-wrapper {
  padding: 4px 20px;
  font-size: 14px;
}
a.mat-stroked-button.body-bg .mat-button-wrapper,
button.mat-stroked-button.body-bg .mat-button-wrapper {
  background-color: var(--base-body);
}
a.mat-stroked-button.base-menu-bg .mat-button-wrapper,
button.mat-stroked-button.base-menu-bg .mat-button-wrapper {
  background-color: var(--base-menu);
}
a.mat-stroked-button.content-bg .mat-button-wrapper,
button.mat-stroked-button.content-bg .mat-button-wrapper {
  background-color: var(--base-content);
}
a.mat-stroked-button.add-button .mat-button-wrapper,
button.mat-stroked-button.add-button .mat-button-wrapper {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  a.mat-stroked-button.add-button .mat-button-wrapper,
button.mat-stroked-button.add-button .mat-button-wrapper {
    padding: 10px;
  }
}
a.mat-stroked-button.card-bg .mat-button-wrapper,
button.mat-stroked-button.card-bg .mat-button-wrapper {
  background-color: var(--base-card);
}
a.mat-stroked-button.card-table-bg .mat-button-wrapper,
button.mat-stroked-button.card-table-bg .mat-button-wrapper {
  background-color: var(--theme-card-lighten-3-darken-3);
}
a.mat-stroked-button.mat-live-color,
button.mat-stroked-button.mat-live-color {
  color: var(--base-live);
}
a.mat-stroked-button.mat-live-color:after,
a.mat-stroked-button.mat-live-color .mat-button-focus-overlay,
button.mat-stroked-button.mat-live-color:after,
button.mat-stroked-button.mat-live-color .mat-button-focus-overlay {
  background: var(--specific-live);
}
a.mat-stroked-button.mat-demo-color,
button.mat-stroked-button.mat-demo-color {
  color: var(--optional-demo);
}
a.mat-stroked-button.mat-demo-color:after,
a.mat-stroked-button.mat-demo-color .mat-button-focus-overlay,
button.mat-stroked-button.mat-demo-color:after,
button.mat-stroked-button.mat-demo-color .mat-button-focus-overlay {
  background: var(--specific-demo);
}
a.mat-stroked-button.mat-live-color:disabled, a.mat-stroked-button.mat-demo-color:disabled,
button.mat-stroked-button.mat-live-color:disabled,
button.mat-stroked-button.mat-demo-color:disabled {
  cursor: not-allowed;
}
a.mat-stroked-button.mat-live-color:disabled:after, a.mat-stroked-button.mat-demo-color:disabled:after,
button.mat-stroked-button.mat-live-color:disabled:after,
button.mat-stroked-button.mat-demo-color:disabled:after {
  background-color: var(--theme-disabled-bg);
  background-image: none;
}
a.mat-stroked-button.mat-live-color:disabled .mat-button-focus-overlay, a.mat-stroked-button.mat-demo-color:disabled .mat-button-focus-overlay,
button.mat-stroked-button.mat-live-color:disabled .mat-button-focus-overlay,
button.mat-stroked-button.mat-demo-color:disabled .mat-button-focus-overlay {
  background-color: transparent;
}
@media (max-width: 480px) {
  a.mat-stroked-button,
button.mat-stroked-button {
    width: 100%;
  }
}

a.mat-icon-button,
button.mat-icon-button {
  transition: background-color 0.15s ease-in-out;
}
a.mat-icon-button .mat-button-wrapper,
button.mat-icon-button .mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--base-accent);
}

.action-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  color: var(--base-accent);
  background: var(--base-card);
  border: 2px solid var(--base-card);
  border-radius: 50%;
  box-shadow: var(--card-box-shadow);
  box-sizing: border-box;
  transition: background 0.2s ease-in, color 0.2s ease-in;
}
.action-button:not(:disabled):hover, .action-button:not(:disabled).active {
  color: var(--base-card) !important;
  background: var(--base-accent);
}
.action-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.action-button app-icon {
  width: 18px;
  height: 18px;
}

.primary-button,
a.primary-button {
  display: inline-block;
  min-width: 100px;
  padding: 10px 13px;
  font-weight: 400;
  text-align: center;
  background: var(--specific-accent);
  border: 1px solid transparent;
  border-radius: 6px;
}
.primary-button, .primary-button:hover, .primary-button:visited, .primary-button:active,
a.primary-button,
a.primary-button:hover,
a.primary-button:visited,
a.primary-button:active {
  color: var(--base-button-color);
}
.primary-button.stroked-button,
a.primary-button.stroked-button {
  background: transparent;
  border-color: var(--base-accent);
}
.primary-button.stroked-button, .primary-button.stroked-button:hover, .primary-button.stroked-button:active,
a.primary-button.stroked-button,
a.primary-button.stroked-button:hover,
a.primary-button.stroked-button:active {
  color: var(--base-accent);
}

.mat-button-ripple .mat-ripple-element {
  background: var(--button-color-opacity-2) !important;
}

.mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay {
  opacity: 0 !important;
}

.mat-button-toggle.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  height: 40px;
  line-height: 40px;
}

mat-card.mat-card {
  background: var(--specific-card);
  border: 1px solid var(--base-card-border);
  border-radius: var(--depricated-card-border-radius);
}
mat-card.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: var(--card-box-shadow);
}

.mat-card-header .mat-card-header-text {
  margin: 0;
}

.card-top-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-top-panel .back-link {
  margin-top: -2px;
  margin-right: 15px;
}
[dir=rtl] .card-top-panel .back-link, .card-top-panel .back-link[dir=rtl] {
  margin-right: 0;
  margin-left: 15px;
}
.card-top-panel .back-link app-icon {
  width: 16px;
  height: 16px;
}

[dir=rtl] .back-link {
  transform: rotate(180deg);
  transform-origin: center;
}

.card-title {
  font: 400 18px/1.3 var(--depricated-font-family-base);
  text-align: center;
}
@media (max-width: 479px) {
  .card-title {
    font-size: 16px;
  }
}

.card-sm,
.mat-card.card-sm {
  max-width: 38%;
  margin: 0 auto;
  padding: 45px 30px;
}
.card-sm .card-top-panel,
.mat-card.card-sm .card-top-panel {
  margin-bottom: 40px;
}
.card-sm .card-top-panel h1,
.mat-card.card-sm .card-top-panel h1 {
  margin-bottom: 0;
}
@media (max-width: 1279px) {
  .card-sm,
.mat-card.card-sm {
    max-width: 54%;
  }
}
@media (max-width: 767px) {
  .card-sm,
.mat-card.card-sm {
    max-width: 63%;
  }
}
@media (min-width: 480px) and (max-width: 599px) {
  .card-sm,
.mat-card.card-sm {
    max-width: 80%;
  }
}
@media (max-width: 479px) {
  .card-sm,
.mat-card.card-sm {
    max-width: 75%;
  }
}

body * {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
body *::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  appearance: none;
}
body *::-webkit-scrollbar-track {
  background-color: var(--card-darken-8);
}
body *::-webkit-scrollbar-thumb {
  background-color: var(--theme-card-lighten-7-darken-15);
  border-radius: 6px;
}
body *::-webkit-scrollbar-corner {
  background-color: var(--card-darken-8);
}

mat-dialog-container.mat-dialog-container {
  min-width: 400px;
  max-width: 600px;
  max-height: 100%;
  overflow: auto;
  background: var(--base-card);
  border-radius: 8px;
  outline: 0;
  box-shadow: none;
  box-sizing: border-box;
}
.place-order-dialog mat-dialog-container.mat-dialog-container {
  max-width: 470px;
  padding: 10px;
}
@media (max-width: 600px) {
  .place-order-dialog mat-dialog-container.mat-dialog-container {
    height: auto;
    overflow-y: auto;
  }
}
.place-order-dialog mat-dialog-container.mat-dialog-container .moex-place-order {
  padding: 0;
}
mat-dialog-container.mat-dialog-container .dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -24px -24px 0;
  padding: 15px;
  font-size: 16px;
  color: var(--base-text);
  border: none;
}
mat-dialog-container.mat-dialog-container .dialog-header .mat-dialog-title {
  margin: 0;
}
mat-dialog-container.mat-dialog-container .mat-dialog-content {
  margin: 0 -24px;
  padding: 15px;
  font-weight: 400;
}
mat-dialog-container.mat-dialog-container .mat-dialog-actions {
  margin: 0 -24px;
  padding: 24px 15px 0;
}
@media (max-width: 480px) {
  mat-dialog-container.mat-dialog-container {
    width: 100%;
    min-width: 250px;
  }
}

.subscribe-dialog {
  height: 100%;
  max-width: 92vw !important;
}
.subscribe-dialog mat-dialog-container.mat-dialog-container {
  padding: 0;
  max-height: 95vh;
  margin: auto;
  height: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
}
.subscribe-dialog mat-dialog-container.mat-dialog-container .dialog-header,
.subscribe-dialog mat-dialog-container.mat-dialog-container .mat-dialog-content,
.subscribe-dialog mat-dialog-container.mat-dialog-container .mat-dialog-actions {
  margin: 0;
}

[mat-dialog-close]:focus,
[mat-dialog-close] {
  padding: 5px;
  color: var(--base-muted);
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  margin-left: 5px;
}
[dir=rtl] [mat-dialog-close]:focus, [mat-dialog-close]:focus[dir=rtl],
[dir=rtl] [mat-dialog-close],
[mat-dialog-close][dir=rtl] {
  margin-left: 0;
  margin-right: 5px;
}
[mat-dialog-close]:focus:hover,
[mat-dialog-close]:hover {
  color: var(--base-accent);
}
[mat-dialog-close]:focus app-icon,
[mat-dialog-close] app-icon {
  width: 10px;
  height: 10px;
  margin: auto;
}

.personal-info-dialog .mat-dialog-container {
  max-height: inherit;
}

.place-order-dialog .moex-place-order .disabled-overlay {
  margin-left: -24px;
  margin-right: -24px;
}
@media (max-width: 960px) {
  .place-order-dialog .moex-place-order .disabled-overlay {
    top: 140px;
  }
}

.eqwire-payment-dialog {
  width: 660px;
}
.eqwire-payment-dialog mat-dialog-container.mat-dialog-container {
  width: 100%;
  max-width: 660px;
}

.transactions-filter-dialog {
  overflow-y: auto;
}
.transactions-filter-dialog .mat-dialog-container {
  min-width: auto;
}

.pbsr-place-order-send mat-dialog-container.mat-dialog-container .mat-dialog-actions {
  gap: 8px;
}
.pbsr-place-order-send mat-dialog-container.mat-dialog-container .mat-dialog-actions button {
  margin: 0;
}
@media (max-width: 480px) {
  .pbsr-place-order-send mat-dialog-container.mat-dialog-container .mat-dialog-actions {
    flex-direction: column;
  }
  .pbsr-place-order-send mat-dialog-container.mat-dialog-container .mat-dialog-actions button {
    flex: 1;
    width: 100%;
  }
}

.mat-form-field .mat-form-field-label-wrapper {
  top: -0.8em;
  padding-top: 0.8em;
  overflow: visible;
}
.mat-form-field .mat-form-field-underline {
  position: static;
}
.mat-form-field .mat-placeholder-required.mat-form-field-required-marker {
  color: var(--base-accent);
}
.mat-form-field .mat-form-field-infix {
  border-top: none;
}

.mat-form-field .mat-form-field-label {
  font-size: 16px;
}

.mat-form-field.mat-form-field-disabled .mat-form-field-label {
  color: var(--disabled-opacity-6);
}

.mat-form-field.mat-form-field-appearance-legacy .mat-form-field-label {
  color: var(--base-muted) !important;
}

.mat-form-field.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-label {
  color: var(--disabled-opacity-6) !important;
}

.mat-form-field.mat-focused.mat-primary .mat-form-field-label {
  color: var(--base-accent);
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  width: 100% !important;
  font-size: 12px;
  transform: translateY(-15px) scale(0.9) perspective(100px) translateZ(0.001px) !important;
}

.mat-form-field.mat-form-field-disabled.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: var(--disabled-opacity-6);
  background-image: none;
}
.mat-form-field.mat-form-field-disabled.mat-form-field-appearance-legacy textarea {
  cursor: not-allowed;
}
.mat-form-field.mat-form-field-disabled .mat-form-field-infix,
.mat-form-field.mat-form-field-disabled .mat-form-field-infix input,
.mat-form-field.mat-form-field-disabled .mat-form-field-infix mat-select,
.mat-form-field.mat-form-field-disabled .mat-form-field-infix .mat-select-placeholder {
  cursor: not-allowed;
}
.mat-form-field.mat-form-field-disabled .mat-select .mat-select-value {
  color: var(--disabled-opacity-6);
}
.mat-form-field.mat-form-field-disabled .mat-select-arrow-wrapper .mat-select-arrow {
  border-color: var(--disabled-opacity-6);
}

.mat-form-field.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: var(--base-border);
}
.mat-form-field.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 9px 0;
}

.mat-form-field.mat-focused.mat-primary .mat-form-field-ripple {
  height: 1px;
  background-color: var(--base-border-active);
}

.mat-form-field.mat-form-field-appearance-outline {
  border-radius: 5px;
}
.mat-form-field.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-form-field.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline,
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline-thick,
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline {
  color: transparent;
}

.form-field-custom-label {
  font-family: var(--depricated-font-family-base);
  font-size: 16px;
  font-weight: 400;
  color: var(--base-muted);
}
.form-field-custom-label .required-marker {
  color: var(--base-accent);
}

.mat-form-field.with-custom-label .mat-form-field-required-marker {
  display: none;
}
.mat-form-field.with-custom-label.mat-form-field-should-float .mat-form-field-label {
  display: none;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
  padding: 0;
}

.mat-form-field-appearance-outline .mat-form-field-infix,
.lots-input.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 10px 0;
}

.account-list .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(-5%);
}
.account-list .mat-form-field-appearance-outline .mat-form-field-outline {
  background: var(--theme-card-darken-2-darken-4);
  border: none;
  border-radius: 8px;
}
.account-list .mat-focused .mat-form-field-outline {
  background: var(--card-darken-5);
  border: 1px solid var(--theme-card-lighten-5-darken-5);
}
.account-list .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
  background-color: var(--card-darken-6) !important;
  opacity: 1;
  transition: opacity 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.account-list .mat-form-field-flex > .mat-form-field-infix .mat-select {
  margin-top: 5px;
}
.account-list .mat-form-field-outline-start,
.account-list .mat-form-field-outline-end {
  border: none !important;
}
.account-list .mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 16px;
}

textarea.mat-input-element {
  resize: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  margin-bottom: -5px;
  padding: 5px 5px 0;
  box-shadow: inset 0 0 0 50px var(--card-darken-1);
  box-sizing: border-box;
  -webkit-text-fill-color: var(--base-text);
}

.mat-form-field .mat-input-element {
  font-size: 14px;
  color: var(--base-text);
  caret-color: var(--base-text);
}

.tight-field {
  padding: 7px 16px;
  color: var(--base-text);
  background: var(--card-darken-6);
  border: 1px solid transparent;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-color 0.15s ease-in-out;
}
.tight-field.disabled {
  background: var(--card-darken-3);
}
.tight-field.error {
  border-color: var(--base-danger);
}
.tight-field input {
  width: 100%;
  color: var(--base-text);
  background: transparent;
  border: none;
  outline: none;
  caret-color: var(--base-text);
}
.tight-field input::placeholder {
  color: var(--base-muted);
}

.transparent-field.mat-form-field-appearance-legacy.mat-form-field,
.transparent-field.mat-form-field-appearance-legacy .mat-form-field-wrapper,
.transparent-field.mat-form-field-appearance-legacy .mat-select-value {
  display: block;
}
.transparent-field.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}
.transparent-field.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper,
.transparent-field.mat-form-field-appearance-legacy .mat-form-field-underline,
.transparent-field.mat-form-field-appearance-legacy .mat-form-field-ripple {
  display: none;
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper[ng-reflect-ng-switch=hint] {
  margin-top: 8px;
}

.mat-form-field-appearance-legacy .mat-form-field-hint-wrapper .mat-hint {
  color: var(--base-muted);
}

.input-prefix {
  width: 25px;
  min-width: 25px;
  margin-right: 15px;
}
[dir=rtl] .input-prefix, .input-prefix[dir=rtl] {
  margin-right: 0;
  margin-left: 15px;
}
.input-prefix span {
  display: inline-block;
  width: 100%;
  height: 25px;
}
.input-prefix app-icon {
  width: 22px;
  height: 22px;
  color: var(--base-muted);
}

.form-field-icon {
  position: relative;
  display: flex;
  width: 100%;
  max-width: calc(100% - var(--depricated-form-field-icon-offset));
  margin-left: var(--depricated-form-field-icon-offset);
}
[dir=rtl] .form-field-icon, .form-field-icon[dir=rtl] {
  margin-left: 0;
  margin-right: var(--depricated-form-field-icon-offset);
}
.form-field-icon .input-prefix {
  position: absolute;
  top: 0;
  right: 100%;
}
[dir=rtl] .form-field-icon .input-prefix, .form-field-icon .input-prefix[dir=rtl] {
  right: auto;
  left: 100%;
}
.form-field-icon .mat-form-field {
  flex: 1 1 100%;
  min-width: 0;
}

.form-field-icon.big {
  max-width: calc(100% - var(--depricated-form-field-icon-offset-big));
  margin-left: var(--depricated-form-field-icon-offset-big);
}
[dir=rtl] .form-field-icon.big, .form-field-icon.big[dir=rtl] {
  margin-left: 0;
  margin-right: var(--depricated-form-field-icon-offset-big);
}
.form-field-icon.big .input-prefix {
  top: auto;
  bottom: -5px;
  width: 50px;
  min-width: 50px;
}
.form-field-icon.big .input-prefix span {
  height: 50px;
}
.form-field-icon.big .input-prefix app-icon {
  width: 35px;
  height: 35px;
}
.form-field-icon.big .mat-select .mat-select-value-text {
  font-size: 24px !important;
}
.form-field-icon.big .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.form-field-icon.big .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-15px) scale(0.9) perspective(100px) translateZ(0.001px) !important;
}

.lite-transfer .mat-select .mat-select-value-text,
.lite-transfer .mat-input-element {
  font-size: 18px !important;
}

.custom-input {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 3px;
  border-bottom: 1px solid var(--base-border);
  transition: border-bottom-color 0.3s ease-in-out;
}
.custom-input.disabled {
  border-bottom-color: var(--disabled-opacity-6);
}
.custom-input .label {
  width: 100%;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 400;
  color: var(--base-muted);
}
.custom-input .input {
  display: flex;
  width: 100%;
}
.custom-input .input[readonly] {
  cursor: default;
}
.custom-input .prefix {
  margin-right: 7px;
  font-size: 24px;
  line-height: 1.15;
  color: var(--base-text);
}
[dir=rtl] .custom-input .prefix {
  margin-left: 7px;
  margin-right: 0;
}
.custom-input input {
  flex-grow: 1;
  padding: 0;
  padding-bottom: 10px;
  font-size: 24px;
  line-height: 1.15;
  color: var(--base-text);
  background: transparent;
  border: none;
  outline: none;
}

.mat-form-field.password-input .mat-form-field-infix {
  padding-right: 25px;
}
[dir=rtl] .mat-form-field.password-input .mat-form-field-infix, .mat-form-field.password-input .mat-form-field-infix[dir=rtl] {
  padding-right: 0;
  padding-left: 25px;
}

.mat-form-field.mat-focused .mat-select .mat-select-arrow {
  transform: rotate(45deg);
}
.mat-form-field .mat-select {
  font-size: 16px;
}
.mat-form-field .mat-select .mat-select-value {
  color: var(--base-text);
}
.mat-form-field .mat-select .mat-select-arrow {
  width: 5px;
  height: 5px;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: var(--base-border);
  transform: rotate(45deg);
}
.mat-form-field .mat-select.color-accent .mat-select-value {
  color: var(--base-accent);
}
.mat-form-field .mat-select.color-accent .mat-select-arrow {
  margin-top: -3px;
  border-color: var(--base-accent);
}

.mat-select-panel-wrap .mat-select-panel {
  background: var(--base-card);
}

.mat-select-panel-wrap .mat-select-panel.mat-select-custom-panel {
  max-height: 48vh;
  margin-top: 10px;
  border-radius: 0;
  margin-left: 10px;
}
[dir=rtl] .mat-select-panel-wrap .mat-select-panel.mat-select-custom-panel, .mat-select-panel-wrap .mat-select-panel.mat-select-custom-panel[dir=rtl] {
  margin-left: 0;
  margin-right: 10px;
}

.mat-select-panel-wrap .mat-select-panel.instrument-select-panel {
  min-width: calc(100% + 10px);
  max-height: 80vh;
  margin-top: 25px;
  margin-left: 10px;
  border-radius: 0;
}
@media (max-height: 768px) {
  .mat-select-panel-wrap .mat-select-panel.instrument-select-panel {
    max-height: 48vh;
  }
}

.mat-select-panel-wrap .mat-select-panel .mat-option {
  color: var(--base-text);
}
.mat-select-panel-wrap .mat-select-panel .mat-option:hover:not(.mat-option-disabled):not(.mat-active) {
  color: var(--base-accent);
  background-color: var(--theme-card-darken-1-darken-3);
}
.mat-select-panel-wrap .mat-select-panel .mat-option.mat-active, .mat-select-panel-wrap .mat-select-panel .mat-option.mat-active:hover {
  color: var(--base-accent);
  background: var(--theme-card-darken-3-darken-5);
}
.mat-select-panel-wrap .mat-select-panel .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--base-accent);
  background: var(--theme-card-darken-3-darken-5);
}
.mat-select-panel-wrap .mat-select-panel .mat-option.mat-selected:not(.mat-option-disabled):hover, .mat-select-panel-wrap .mat-select-panel .mat-option.mat-selected:not(.mat-option-disabled).mat-active {
  color: var(--base-accent);
  background: var(--theme-card-darken-5-darken-6);
}

.mat-select-panel-wrap app-balance {
  display: inline;
}

.verification-form app-select {
  display: inline-block;
}
.verification-form .mat-form-field {
  width: auto;
}

.currency-select .mat-form-field-infix {
  width: auto;
  min-width: 20px;
}
.currency-select .mat-select-value {
  max-width: 200px;
  font-size: 24px;
  margin-right: 5px;
}
[dir=rtl] .currency-select .mat-select-value, .currency-select .mat-select-value[dir=rtl] {
  margin-right: 0;
  margin-left: 5px;
}
@media (max-width: 479px) {
  .currency-select .mat-select-value {
    font-size: 18px;
    line-height: 30px;
  }
}

.pbs-select .mat-optgroup-label,
.pbs-select .mat-option-text {
  font-size: 14px;
}

.pbs-select .mat-optgroup-label {
  font-weight: 400;
  color: var(--base-disabled);
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: var(--theme-card-darken-5-darken-6);
}

.mat-option-text .highlight {
  color: var(--base-accent);
}

.mat-checkbox {
  width: 100%;
}
.mat-checkbox .mat-checkbox-layout {
  align-items: flex-start;
  width: 100%;
  white-space: normal;
}
.mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  flex-grow: 1;
  line-height: 20px;
}
.mat-checkbox .mat-checkbox-label {
  color: var(--base-text);
}
.mat-checkbox .mat-checkbox-inner-container {
  width: 15px;
  height: 15px;
  margin: 2px 10px 0 0;
}
.mat-checkbox .mat-checkbox-frame {
  border-width: 1px;
  border-color: var(--base-border);
}

.mat-checkbox.mat-checkbox-disabled {
  cursor: not-allowed;
}
.mat-checkbox.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: var(--disabled-opacity-6);
}
.mat-checkbox.mat-checkbox-disabled .mat-checkbox-label {
  color: var(--disabled-opacity-6);
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--base-accent) !important;
}

.mat-radio-container .mat-radio-outer-circle {
  border-color: var(--base-accent);
}

.mat-radio-label-content {
  color: var(--base-text);
}

.type-live .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
.type-live .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: var(--base-live);
}

.type-demo .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
.type-demo .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: var(--optional-demo);
}

.type-master.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element, .type-master.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: var(--master-account);
}

.type-investment.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element, .type-investment.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: var(--follower-account);
}

.mat-select-panel.occupation-select-panel .mat-optgroup-label,
.mat-select-panel.occupation-select-panel .mat-option {
  height: auto;
  min-height: 3em;
}
.mat-select-panel.occupation-select-panel .mat-optgroup-label .mat-option-text,
.mat-select-panel.occupation-select-panel .mat-option .mat-option-text {
  display: block;
  padding: 10px 0;
  overflow: visible;
  line-height: 1.8;
  white-space: normal;
}

.advanced-account-select-panel.mat-select-panel {
  position: absolute;
  top: 45px;
  max-width: 220px;
  max-height: 400px;
  border: 1px solid var(--theme-card-lighten-5-darken-5);
  border-radius: 8px;
}
.advanced-account-select-panel.mat-select-panel .mat-option {
  min-width: 80px;
  height: auto;
  padding: 16px;
  line-height: 1.3;
}
.advanced-account-select-panel.mat-select-panel .mat-option span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mat-select.tight-field .mat-select-arrow {
  width: 5px;
  height: 5px;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: var(--base-muted);
  transform: rotate(45deg);
}

.mat-menu-panel {
  background: var(--base-card) !important;
}
.mat-menu-panel .mat-menu-item {
  color: var(--base-text);
}
.mat-menu-panel .mat-menu-item:hover {
  color: var(--base-text);
  background: var(--theme-card-lighten-4-darken-4);
}
.mat-menu-panel .mat-menu-item[disabled], .mat-menu-panel .mat-menu-item:disabled {
  color: var(--text-opacity-5);
}
.mat-menu-panel .mat-menu-content:not(:empty) {
  padding-top: 0;
  padding-bottom: 0;
}

.mat-menu-panel.notification-menu {
  min-height: 0;
  border-radius: 8px;
}
.mat-menu-panel.notification-menu .mat-menu-item {
  text-align: right;
}
[dir=rtl] .mat-menu-panel.notification-menu .mat-menu-item, .mat-menu-panel.notification-menu .mat-menu-item[dir=rtl] {
  text-align: left;
}

.mat-paginator {
  background: transparent;
}
.mat-paginator button:disabled .mat-paginator-icon {
  fill: var(--base-muted);
}

.mat-slide-toggle-label .mat-slide-toggle-bar {
  height: 24px;
  background: var(--base-muted);
  border-radius: 12px;
}
.mat-slide-toggle-label .mat-slide-toggle-thumb-container {
  top: 2px;
  left: 2px;
}
[dir=rtl] .mat-slide-toggle-label .mat-slide-toggle-thumb-container, .mat-slide-toggle-label .mat-slide-toggle-thumb-container[dir=rtl] {
  left: auto;
  right: 2px;
}
.mat-slide-toggle-label .mat-slide-toggle-thumb {
  background-color: var(--base-button-color);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-label .mat-slide-toggle-bar {
  background: var(--base-accent);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-label .mat-slide-toggle-thumb {
  background-color: var(--base-button-color);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-label .mat-slide-toggle-thumb-container {
  transform: translate3d(12px, 0, 0);
}
[dir=rtl] .mat-slide-toggle.mat-checked .mat-slide-toggle-label .mat-slide-toggle-thumb-container, .mat-slide-toggle.mat-checked .mat-slide-toggle-label .mat-slide-toggle-thumb-container[dir=rtl] {
  transform: translate3d(-12px, 0, 0);
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background: transparent !important;
}
.mat-stepper-horizontal.hide-header .mat-horizontal-stepper-header-container,
.mat-stepper-vertical.hide-header .mat-horizontal-stepper-header-container {
  display: none !important;
}
.mat-stepper-horizontal .mat-horizontal-content-container,
.mat-stepper-vertical .mat-horizontal-content-container {
  padding: 0 !important;
}

.mat-tab-group.tabs-buttons,
.mat-tab-nav-bar.tabs-buttons {
  z-index: 1;
}
.mat-tab-group.tabs-buttons,
.mat-tab-group.tabs-buttons .mat-tab-header,
.mat-tab-nav-bar.tabs-buttons,
.mat-tab-nav-bar.tabs-buttons .mat-tab-header {
  border-bottom: none;
}
.mat-tab-group.tabs-buttons .mat-tab-link-container,
.mat-tab-group.tabs-buttons .mat-tab-label-container,
.mat-tab-group.tabs-buttons .mat-tab-header .mat-tab-link-container,
.mat-tab-group.tabs-buttons .mat-tab-header .mat-tab-label-container,
.mat-tab-nav-bar.tabs-buttons .mat-tab-link-container,
.mat-tab-nav-bar.tabs-buttons .mat-tab-label-container,
.mat-tab-nav-bar.tabs-buttons .mat-tab-header .mat-tab-link-container,
.mat-tab-nav-bar.tabs-buttons .mat-tab-header .mat-tab-label-container {
  flex-grow: 0;
  background: var(--card-darken-5);
  border-radius: 5px;
}
.mat-tab-group.tabs-buttons .mat-tab-link,
.mat-tab-group.tabs-buttons .mat-tab-label,
.mat-tab-nav-bar.tabs-buttons .mat-tab-link,
.mat-tab-nav-bar.tabs-buttons .mat-tab-label {
  position: relative;
  min-width: 120px;
  height: 34px;
  padding: 8px 15px;
  font-weight: 400;
  color: var(--base-accent);
  box-sizing: border-box;
  opacity: 1;
  transition: color 0.4s ease-in-out;
}
.mat-tab-group.tabs-buttons .mat-tab-link:hover,
.mat-tab-group.tabs-buttons .mat-tab-label:hover,
.mat-tab-nav-bar.tabs-buttons .mat-tab-link:hover,
.mat-tab-nav-bar.tabs-buttons .mat-tab-label:hover {
  color: var(--accent-lighten-10);
}
.mat-tab-group.tabs-buttons .mat-tab-link-active,
.mat-tab-group.tabs-buttons .mat-tab-label-active,
.mat-tab-nav-bar.tabs-buttons .mat-tab-link-active,
.mat-tab-nav-bar.tabs-buttons .mat-tab-label-active {
  color: var(--base-button-color);
  border-radius: 5px;
  cursor: default;
}
.mat-tab-group.tabs-buttons .mat-tab-link-active:before,
.mat-tab-group.tabs-buttons .mat-tab-label-active:before,
.mat-tab-nav-bar.tabs-buttons .mat-tab-link-active:before,
.mat-tab-nav-bar.tabs-buttons .mat-tab-label-active:before {
  background: var(--base-accent);
}
.mat-tab-group.tabs-buttons .mat-tab-link-active:hover,
.mat-tab-group.tabs-buttons .mat-tab-label-active:hover,
.mat-tab-nav-bar.tabs-buttons .mat-tab-link-active:hover,
.mat-tab-nav-bar.tabs-buttons .mat-tab-label-active:hover {
  color: var(--base-button-color);
}
.mat-tab-group.tabs-buttons .mat-tab-link-active.mat-focus-indicator.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.tabs-buttons .mat-tab-label-active.mat-focus-indicator.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.tabs-buttons .mat-tab-link-active.mat-focus-indicator.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.tabs-buttons .mat-tab-label-active.mat-focus-indicator.cdk-keyboard-focused:not(.mat-tab-disabled) {
  color: var(--base-button-color);
}
.mat-tab-group.tabs-buttons .mat-tab-link.mat-focus-indicator.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.tabs-buttons .mat-tab-label.mat-focus-indicator.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.tabs-buttons .mat-tab-link.mat-focus-indicator.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.tabs-buttons .mat-tab-label.mat-focus-indicator.cdk-keyboard-focused:not(.mat-tab-disabled) {
  color: var(--accent-lighten-10);
  background: transparent;
}
.mat-tab-group.tabs-buttons .mat-ink-bar,
.mat-tab-nav-bar.tabs-buttons .mat-ink-bar {
  height: 100%;
  background: var(--base-accent) !important;
  border: 1px solid transparent;
  border-radius: 5px;
  box-sizing: border-box;
  visibility: visible !important;
  z-index: -1;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled) {
  background: transparent;
}

.title-link {
  font-size: 24px;
  line-height: 1.6;
}
.title-link a {
  display: inline;
  margin-bottom: 10px;
  padding-bottom: 3px;
  border-bottom: 1px dashed;
  cursor: pointer;
  transition: border-bottom-color 0.15s ease-in-out, color 0.15s ease-in-out;
}
.title-link a:hover {
  border-bottom-color: transparent;
}
@media (max-width: 1279px) {
  .title-link {
    font-size: 22px;
  }
}
@media (max-width: 599px) {
  .title-link {
    font-size: 20px;
  }
}
@media (max-width: 479px) {
  .title-link {
    font-size: 18px;
    text-align: center;
  }
}

.custom-tooltip,
.mat-tooltip {
  margin: 10px !important;
  color: var(--base-text) !important;
  word-wrap: break-word;
  background: var(--base-card) !important;
  border: 1px solid var(--base-border) !important;
}

.pbsr-tooltip {
  margin: 10px !important;
  word-wrap: break-word;
  background: var(--base-card);
  border: 1px solid var(--base-menu);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  pointer-events: none;
}

.file-error-tooltip.mat-tooltip {
  font-size: 14px;
  color: var(--base-danger) !important;
  border: 1px solid var(--base-danger) !important;
}

.fee-tooltip.mat-tooltip {
  margin: 5px !important;
  font-size: 12px;
  background: var(--base-submenu) !important;
  border: 1px solid var(--base-card-border) !important;
  border-radius: 5px;
}

.shepherd-modal-overlay-container {
  opacity: 1 !important;
  fill: var(--body-opacity-55);
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  pointer-events: visible;
  z-index: 900;
}

div.shepherd-element,
.shepherd-element.shepherd-has-title header.shepherd-header {
  color: var(--base-text);
  background: var(--base-card);
  z-index: 900;
}
div.shepherd-element .shepherd-arrow:before,
.shepherd-element.shepherd-has-title header.shepherd-header .shepherd-arrow:before {
  background: var(--base-card) !important;
}
div.shepherd-element .shepherd-title,
.shepherd-element.shepherd-has-title header.shepherd-header .shepherd-title {
  justify-content: center;
  color: var(--base-text);
  transform: translateY(40px);
}

header.shepherd-header .shepherd-cancel-icon {
  color: var(--base-muted);
}

.shepherd-has-title div.shepherd-text {
  padding-top: 60px;
  font-size: 12px;
}

div.shepherd-text {
  padding: 10px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--base-text);
}

.shepherd-content ul {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.shepherd-content ul .step-pointer {
  display: block;
  width: 5px;
  height: 5px;
  margin: 0 2px;
  background: var(--base-text);
  border-radius: 10px;
  opacity: 0.3;
}
.shepherd-content ul .step-pointer.active {
  opacity: 1;
}

footer.shepherd-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
footer.shepherd-footer button {
  min-width: 100px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: var(--base-accent);
  background: transparent;
}
footer.shepherd-footer button:not(:disabled):hover {
  color: var(--base-accent);
  background: var(--accent-opacity-1);
}

.mat-datepicker-toggle button.mat-icon-button .mat-button-wrapper {
  position: relative;
  bottom: 1.5px;
  display: block;
}
.mat-datepicker-toggle button.mat-icon-button .mat-datepicker-toggle-default-icon {
  transition: fill 0.3s ease-in-out;
  fill: var(--base-muted);
}
.mat-datepicker-toggle button.mat-icon-button .mat-datepicker-toggle-default-icon:hover, .mat-datepicker-toggle button.mat-icon-button .mat-datepicker-toggle-default-icon:active {
  fill: var(--base-text);
}
.mat-datepicker-toggle button.mat-icon-button[disabled] .mat-datepicker-toggle-default-icon:hover, .mat-datepicker-toggle button.mat-icon-button[disabled] .mat-datepicker-toggle-default-icon:active {
  fill: var(--base-muted);
}

.mat-datepicker-toggle .mat-icon-button.cdk-focused.cdk-keyboard-focused .mat-button-focus-overlay {
  opacity: 0;
}
.mat-datepicker-toggle .mat-icon-button.cdk-focused.cdk-keyboard-focused .mat-datepicker-toggle-default-icon {
  transition: fill 0.3s ease-in-out;
  fill: var(--base-text);
}

.mat-datepicker-toggle.mat-datepicker-toggle-active .mat-datepicker-toggle-default-icon {
  fill: var(--base-text);
}

mat-datepicker-content.mat-datepicker-content {
  color: var(--base-text);
  background-color: transparent;
}
mat-datepicker-content.mat-datepicker-content .mat-calendar {
  color: var(--base-muted);
  background-color: var(--base-card);
}
mat-datepicker-content.mat-datepicker-content .mat-calendar-table-header {
  color: var(--base-muted);
}
mat-datepicker-content.mat-datepicker-content .mat-calendar-body-label {
  color: var(--base-muted);
}
mat-datepicker-content.mat-datepicker-content .mat-calendar .mat-calendar-body-cell-content {
  color: var(--base-text);
}
mat-datepicker-content.mat-datepicker-content .mat-calendar .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: var(--muted-opacity-7);
}
mat-datepicker-content.mat-datepicker-content .mat-calendar button.mat-calendar-period-button.mat-button {
  color: var(--base-text);
}
mat-datepicker-content.mat-datepicker-content .mat-calendar button.mat-calendar-period-button.mat-button:hover {
  background: var(--muted-opacity-1);
}
mat-datepicker-content.mat-datepicker-content .mat-calendar .mat-calendar-next-button,
mat-datepicker-content.mat-datepicker-content .mat-calendar .mat-calendar-previous-button,
mat-datepicker-content.mat-datepicker-content .mat-calendar .mat-calendar-arrow {
  color: var(--base-text);
  border-top-color: var(--base-text);
  fill: var(--base-text);
}
mat-datepicker-content.mat-datepicker-content .mat-calendar .mat-calendar-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background: var(--muted-opacity-7);
}
mat-datepicker-content.mat-datepicker-content .mat-calendar .mat-icon-button[disabled][disabled] {
  color: var(--muted-opacity-7);
}
mat-datepicker-content.mat-datepicker-content .mat-calendar .mat-calendar-body-selected {
  color: var(--base-accent);
  background: var(--muted-opacity-1);
}
mat-datepicker-content.mat-datepicker-content .mat-calendar .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--base-accent);
}
mat-datepicker-content.mat-datepicker-content .mat-calendar .mat-calendar-today:not(.mat-calendar-body-selected) {
  border-color: var(--muted-opacity-5);
}
mat-datepicker-content.mat-datepicker-content .mat-calendar .mat-calendar-table-header-divider:after {
  background: var(--muted-opacity-1);
}